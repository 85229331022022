<template>
  <div class="portal">
    <Header></Header>
    <div class="mainWrapper" style="position: relative; height: 675px">
      <Main></Main>
    </div>
    <Bottom></Bottom>
  </div>
</template>

<script>
import * as utils from "@/common/js/utils.js";
import Header from "./header";
import Main from "./main";
import Bottom from "./bottom";

export default {
  name: "portal",
  provide() {
    return {
      rootParent: (() => this)(),
    };
  },
  components: {
    Header,
    Main,
    Bottom,
  },
  data() {
    return {
      backgroundUrl: require("./img/protal_background.png"),
    };
  },
  methods: {
    findFirstMenu(menu) {
      if (menu.children && menu.children.length) {
        return this.findFirstMenu(menu.children[0]);
      }
      return menu;
    },
    getUrl() {
      let url = "";
      console.log(1113, process.env);
      if (process.env.VUE_APP_BUILD_MODE === "pre") {
        url = `http://acc-pre.ace.aipark.com/autoLogin?userId=${
          sessionStorage.userId
        }&t=${sessionStorage.token}&u=${
          sessionStorage.userName
        }&timestamp=${new Date().getTime()}`;
      } else {
        url = `http://acc.ace.aipark.com/autoLogin?userId=${
          sessionStorage.userId
        }&t=${sessionStorage.token}&u=${
          sessionStorage.userName
        }&timestamp=${new Date().getTime()}`;
      }
      return url;
    },
    portalClickHandle(path) {
      switch (true) {
        case path == "CHARGING_BUSINESS":
          window.open(this.getUrl());
          break;
        default: {
          const portalMenu = utils.findPortalMenu(
            this.$store.state.menu.authorityMenu,
            path
          );
          this.$store.dispatch("menu/CHANGE_MENU", portalMenu);
          this.$router.push(this.findFirstMenu(portalMenu[0]).webUri);
        }
      }
    },
  },
  mounted() {
    this.$store.dispatch("menu/CHANGE_MENU", []);
    this.$store.dispatch("tabs/REMOVE_TABS");
    if (this.backgroundUrl) {
      this.backgroundUrl = sessionStorage.backgroundUrl;
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
.portal {
  background: url('./img/protal_background.png') center / cover no-repeat;
  height: 100%;
  min-width: 1016px;
  overflow: auto;
  display: flex;
  flex-direction: column;

  .mainWrapper {
    flex-shrink: 0;
    flex-grow: 1;
  }
}
</style>
