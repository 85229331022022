var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "headerBottom" }, [
    _vm._v(
      " " + _vm._s(_vm.footer ? _vm.footer : "智慧互通科技股份有限公司") + " "
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }