<template>
  <div class="portalHeader">
    <div class="left">
      <div class="logo">
        <i
          v-if="!logoUrl"
          style="font-size: 18px; color: #000; cursor: pointer"
          class="iconfont iconACSlogo"
        ></i>
        <img
          v-if="logoUrl"
          :src="logoUrl"
          style="max-height: 32px; cursor: pointer; margin-right: 6px"
          alt=""
        />
        {{ platformName ? platformName : "智慧泊车管理平台" }}
      </div>
    </div>
    <div class="right">
      <!-- <div class="system" @click="showUnread">
          <i class="iconfont icondaping" @click="godaping"></i>
          <i class="iconfont iconxiaoxi1"></i>
          <span class="unreadNum" v-show="infoCount > 0">{{
            infoCount > 99 ? "..." : infoCount
          }}</span>
          <transition name="fade">
            <systemDialog class="systemDialog" v-if="systemDialogShow" :unreadData="unreadData" />
          </transition>
        </div> -->
      <div class="userBox">
        <div class="userImg" @click="showUnread" style="cursor: pointer">
          <svg-icon icon-class="navbar-right"></svg-icon>
          <span class="unreadNum" v-show="infoCount > 0"></span>
          <transition name="fade">
            <systemDialog class="systemDialog" v-if="systemDialogShow" :unreadData="unreadData" />
          </transition>
        </div>
        <div class="userImg">
          <img src="./img/userImg.png" />
        </div>
        <el-dropdown trigger="click">
          <span class="el-dropdown-link username" style="cursor: pointer">
            {{ $store.state.username }}&nbsp;&nbsp;<i
              class="el-icon-arrow-down"
              style="font-size: 12px"
            ></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <span
              @click="
                $refs.ruleForm && $refs.ruleForm.resetFields();
                dialogFormVisible = true;
              "
              ><el-dropdown-item
                ><i class="el-icon-edit"></i>&nbsp;&nbsp;修改密码</el-dropdown-item
              ></span
            >
            <span @click="logout"
              ><el-dropdown-item
                ><i class="el-icon-circle-close"></i>&nbsp;&nbsp;退出</el-dropdown-item
              ></span
            >
          </el-dropdown-menu>
        </el-dropdown>
        <el-dialog
          width="450px"
          :append-to-body="true"
          title="修改密码"
          :visible.sync="dialogFormVisible"
        >
          <el-form :model="form" label-position="right" :rules="rule" ref="ruleForm">
            <el-form-item label="原密码" label-width="80px" prop="password">
              <el-input
                style="width: 220px"
                v-model.trim="form.password"
                auto-complete="off"
                placeholder="请输入旧密码"
                type="password"
              ></el-input>
            </el-form-item>
            <el-form-item label="确认密码" label-width="80px" prop="newPassword">
              <el-input
                style="width: 220px"
                v-model.trim="form.newPassword"
                auto-complete="off"
                placeholder="请输入新密码"
                type="password"
              ></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisible = false">取 消</el-button>
            <el-button type="primary" @click="modifyPwd">确 定</el-button>
          </div>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import systemDialog from "../../components/systemDialog/systemDialog";
import * as utils from "@/common/js/utils.js";
import Vue from "vue";
import { jsMd5, dateFormat, getDt } from "@/common/js/public.js";

export default {
  name: "portalHeader",
  data() {
    let validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入旧密码"));
        // } else if (/^[\w|@]{6,15}$/.test(value)) {
      } else if (/(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[^a-zA-Z0-9]).{8,20}/.test(value)) {
        callback();
      } else {
        // return callback(new Error("密码只能是数字、字母、特殊字符或下划线且长度在6-15位"));
        return callback(new Error("大写字母+小写字母+数字+特殊字符，至少8位"));
      }
    };
    let validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入新密码"));
        // } else if (/^[\w|@]{6,15}$/.test(value)) {
      } else if (/(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[^a-zA-Z0-9]).{8,20}/.test(value)) {
        callback();
      } else {
        // return callback(new Error("密码只能是数字、字母、特殊字符或下划线且长度在6-15位"));
        return callback(new Error("大写字母+小写字母+数字+特殊字符，至少8位"));
      }
    };
    return {
      logoUrl: sessionStorage.logoUrl,
      platformName: sessionStorage.platformName,
      timmer: null,
      timerNotification: null,
      systemDialogShow: false,
      unreadData: [],
      infoCount: 0,
      timeCount: 1,
      time1: "", // 通知消息出现弹框的时间
      time2: "", // 监控车辆报警消息出现弹框的时间
      dialogFormVisible: false,
      rule: {
        // oldPwd: [
        //   { validator: validateOldPass, trigger: 'blur' }
        // ],
        password: [{ validator: validatePass, trigger: "blur" }],
        newPassword: [{ validator: validatePass2, trigger: "blur" }],
      },
      form: {
        password: "",
        newPassword: "",
      },
    };
  },
  components: {
    systemDialog,
  },
  beforeDestroy() {
    clearInterval(this.timmer);
    clearInterval(this.timerNotification);
    if (this.notify) {
      this.notify.visible = false;
    }
  },
  methods: {
    godaping() {
      let url = "";
      console.log(" sessionStorage.token", sessionStorage.token);
      window.open(
        `https://dataview-pre.acs.aipark.com/report/share.html?session=${sessionStorage.token}&shareToken=eNoNzLkBwDAIBLCVbD5DeXz7j5RU6pTopyf4mTaP0Um4WqBvwGIWxBK-nlwu3chQlfHJW2HkugxrotslnlVlvUdfe4nZAUH_qF1w4ofLhLZ1OHkIouwfY6AdRg&type=display#share/display`
      );
    },
    showUnread() {
      this.systemDialogShow = !this.systemDialogShow;
    },
    logout() {
      this.$confirm("是否退出?", this.$t('pop_up.Tips'), {
        confirmButtonText: this.$t('pop_up.Determine'),
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let opt = {
            url: "/acb/2.0/systems/login/logout",
            method: "post",
            data: {
              sessionId: sessionStorage.token,
            },
            success: (res) => {
              this.$message({
                type: "success",
                message: "退出成功!",
              });
              sessionStorage.clear();
              this.$router.push("/");
            },
          };
          this.$request(opt);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消退出",
          });
        });
    },
    timedTask() {
      this.$axios
        .get("acb/2.0/sysmgMsgConf/recordNumberByGroup", {
          data: {
            userId: sessionStorage.userId,
            unread: 0,
          },
        })
        .then((res) => {
          console.log(res);
          // this.unreadData = [];
          // this.unreadData = res.value || [];
          // this.infoCount = 0;
          if (res.value && res.value.length > 0) {
            // 处理设备报警消息权限
            // if (!isMenu("/equipmentAlarmInfo")) {
            //   this.unreadData = this.unreadData.filter(item => item.msgType !== 3) || [];
            // }
            // if (!isMenu("/vehicleAlarmInfo")) {
            //   this.unreadData = this.unreadData.filter(item => item.msgType !== 2) || [];
            // }
            // if (!isMenu("/systemNotice")) {
            //   this.unreadData = this.unreadData.filter(item => item.msgType !== 0) || [];
            // }
            var infoCount = 0;
            res.value.map((v) => {
              if (v.recordNumber) {
                infoCount = v.recordNumber + infoCount;
              }
            });
            // 最后把新数据赋值给老数据
            this.unreadData = res.value || [];
            this.infoCount = infoCount;
          }
        });
    },
    getUnreadData(type) {
      sessionStorage.addTab = 1;
      let data = {};
      if (type == 1) {
        data = {
          desc: "通知消息",
          webUri: "systemNotice",
        };
      } else if (type == 2) {
        data = {
          desc: "监控车辆报警消息",
          webUri: "vehicleAlarmInfo",
        };
      }
      let path = "SYSTEM_MANAGEMENT";
      const portalMenu = utils.findPortalMenu(this.$store.state.menu.authorityMenu, path);
      this.$store.dispatch("menu/CHANGE_MENU", portalMenu);
      this.$router.push(data.webUri);
    },
    modifyPwd() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let d = { ...this.form };
          d.password = jsMd5(sessionStorage.account, jsMd5(sessionStorage.account, d.password));
          d.newPassword = jsMd5(sessionStorage.account, d.newPassword);
          let opt = {
            method: "post",
            url: "/acb/2.0/systems/login/modifyPassword",
            data: d,
            success: (res) => {
              if (res.state == 0) {
                this.$message({
                  type: "info",
                  message: "密码修改成功，请重新登录",
                });
                this.dialogFormVisible = false;
                this.$router.replace("/");
              } else {
                this.$alert(res.desc, this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine'),
                });
              }
            },
          };
          this.$request(opt);
        } else {
          return false;
        }
      });
    },
    // 车辆监控报警
    vehicleAlarmRecord() {
      // 点击小铃铛重新获取数据
      let opt = {
        url: "/acb/2.0/sysmgMsgConf/vehicleAlarmRecord",
        method: "get",
        data: {
          userId: sessionStorage.userId,
          carId: "",
          page: 1,
          pageSize: 15,
          startTime: sessionStorage.time2 || sessionStorage.loginTime,
        },
        success: (res) => {
          let that = this;
          if (res.state == 0) {
            let tableData = res.value.list;
            if (tableData[0].unread === 0) {
              // notification
              // 通知开关开并且有新消息时
              if (sessionStorage.blockNotification == 0) {
                var type = 2;
                // 确定需要弹窗，type是需要弹窗的类型
                sessionStorage.notificationShowing = 1;
                let options = {
                  type: type,
                  content: type == 1 ? "您有新的通知消息" : "您有新的监控车辆报警消息",
                  clickSure: () => {
                    that.getUnreadData(type);
                  },
                };
                if (!that.notify) {
                  // 如果没有通知过，加载通知
                  that.notify = this.$notification(options);
                } else {
                  // 如果通知过，修改通知属性显示最新的需要弹窗的类型
                  that.notify.type = type;
                  that.notify.content = type == 1 ? "您有新的通知消息" : "您有新的监控车辆报警消息";
                  that.notify.clickSure = () => {
                    that.getUnreadData(type);
                  };
                  Vue.nextTick(() => {
                    that.notify.visible = true;
                  });
                }
              }
            }
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
          sessionStorage.time2 = dateFormat(new Date());
        },
      };
      getDt(opt);
    },
    // 车辆监控报警
    infoAlarmRecord() {
      // 点击小铃铛重新获取数据
      let opt = {
        url: "/acb/2.0/sysmgMsgConf/recordlist",
        method: "get",
        data: {
          userId: sessionStorage.userId,
          msgType: 0,
          page: 1,
          pageSize: 10,
          startTime: sessionStorage.time1 || sessionStorage.loginTime,
        },
        success: (res) => {
          let that = this;
          if (!res.value) return;
          if (res.state == 0) {
            let tableData = res.value.list || [];
            if (tableData[0].unread === 0) {
              // notification
              // 通知开关开并且有新消息时
              if (sessionStorage.blockNotification == 0) {
                var type = 1;
                // 确定需要弹窗，type是需要弹窗的类型
                sessionStorage.notificationShowing = 1;
                let options = {
                  type: type,
                  content: type == 1 ? "您有新的通知消息" : "您有新的监控车辆报警消息",
                  clickSure: () => {
                    that.getUnreadData(type);
                  },
                };
                if (!that.notify) {
                  // 如果没有通知过，加载通知
                  that.notify = that.$notification(options);
                } else {
                  // 如果通知过，修改通知属性显示最新的需要弹窗的类型
                  that.notify.type = type;
                  that.notify.content = type == 1 ? "您有新的通知消息" : "您有新的监控车辆报警消息";
                  that.notify.clickSure = () => {
                    that.getUnreadData(type);
                  };
                  Vue.nextTick(() => {
                    that.notify.visible = true;
                  });
                }
              }
            }
          }
          sessionStorage.time1 = dateFormat(new Date());
        },
      };
      getDt(opt);
    },
  },
  mounted() {
    // sessionStorage.loginTime = dateFormat(new Date());
    if (sessionStorage.userId) {
      this.timedTask();
    }
    // 定时任务
    this.timmer = setInterval(() => {
      if (sessionStorage.userId) {
        this.timedTask();
      } else {
        clearInterval(this.timmer);
      }
    }, 60000);
    this.timerNotification = setInterval(() => {
      // sessionStorage.loginTime = dateFormat(new Date());
      if (this.timeCount == 1) {
        this.infoAlarmRecord();
        this.timeCount = 2;
      } else if (this.timeCount == 2) {
        this.vehicleAlarmRecord();
        this.timeCount = 1;
      }
    }, 30000);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
  .unreadNum
    position: relative;
    display: block;
    top: -28px;
    right: -20px;
    line-height: 16px;
    text-align: center;
    width: 4px;
    height: 4px;
    background: #ff0000;
    border-radius: 50%;
    color: #fff;
  .systemDialog
    position absolute
    top 46px
    right 4px
    z-index 9999
    >>>.triangle
      left 50%

.portalHeader
  width 100%
  flex-shrink 0
  display flex
  align-items center
  justify-content space-between
.left
  .logo
    // background url(./img/logo.png)
    // background-size 100% 100%
    // width 410px
    margin-left: 12px
    height: 48px;
    font-size: 18px;
    font-family: 'PingFang';
    font-weight: 500;
    color: #1D2129;
    display: flex;
    align-items: center;
    img
      max-height:32px;

.right
  margin-right 12px
.userBox
  display: flex;
  align-items: center;
  justify-content: center;
.userBox div
  display inline-block
.userImg
  width 32px
  height 32px
  opacity 1
  margin-left 12px
  color: #f5f6f7
  &:hover
    color: #E6E8EB
  svg, img
   width: 100%
   height: 100%
  .user
    display flex
    min-width 186px
    align-items center

.username
  font-size: 14px;
  font-family: 'PingFang';
  font-weight: 400;
  color: #1F2027;
  margin-left 6px
.portalHeader
  width 100%
  height: 48px
  background: #fff;

.iconxiaoxi1,.icontuichu {
  font-size: 20px;
  color:#666;
}
.iconxiaoxi1:hover,.icontuichu:hover{
  color:#0f6eff
}
.icondaping {
  font-size: 20px;
  color:#fff;
}
.icondaping:hover,.icontuichu:hover{
  color:#0f6eff
}
</style>
