var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "portal" },
    [
      _c("Header"),
      _c(
        "div",
        {
          staticClass: "mainWrapper",
          staticStyle: { position: "relative", height: "675px" },
        },
        [_c("Main")],
        1
      ),
      _c("Bottom"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }