<template>
  <div class="headerBottom">
    {{ footer ? footer : "智慧互通科技股份有限公司" }}
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  data() {
    return {
      footer: sessionStorage.footer,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
.headerBottom{
  line-height: 36px;
  text-align: center;
  height: 36px;
  background: rgba(146,177,245,0.3);
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  font-size:12px;
}
</style>
