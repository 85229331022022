<template>
  <div
    :class="data.length > 4 ? 'portalMain' : 'portalMainTop'"
    :style="{ width: elementWidth + 'px' }"
  >
    <!-- <PortalItem :top="true" :ifLink="false" :canClick="false" imgSrc="./img/aipark.png">
      <template #bottom>
        <div class="pWord">一个平台，畅停全城</div>
        <div class="pWord">通行中国，连接世界</div>
      </template>
    </PortalItem> -->
    <template v-for="(item, ind) in data">
      <PortalItem
        :ifLink="item.ifLink"
        :top="item.top"
        :key="ind"
        :item="item"
        :imgSrc="item.imgSrc"
        :dataLength="data.length"
      >
        <template #bottom>
          <div :class="data.length > 4 ? 'moduleNameMin' : 'moduleNameMax'">{{ item.mName }}</div>
          <div :class="data.length > 4 ? 'englishNameMin' : 'englishNameMax'">{{ item.eName }}</div>
          <!-- <div
            :class="data.length > 4 ? 'sTopImgMin' : 'sTopImgMax'"
            :style="{ background: `url(${require(`${item.imgSrc}`)})` }"
          ></div> -->
          <!-- <img :class="data.length > 4 ? 'sTopImgMin' : 'sTopImgMax'" src="./img/testProtal.png" /> -->
        </template>
      </PortalItem>
    </template>
  </div>
</template>

<script>
import PortalItem from "./item";
export default {
  name: "portalMain",
  data() {
    let au = this.$store.state.menu.authorityMenu;
    return {
      elementWidth: 1016,
      data: [
        {
          imgSrc: "./img/parkManage.png",
          mName: "路内停车管理系统",
          eName: "In-road Parking Management System",
          top: true,
          link: "PARKING_BUSINESS",
          ifLink: !!au["PARKING_BUSINESS"], // 是否有权限
        },
        {
          imgSrc: "./img/roadout.png",
          mName: "路外停车管理系统",
          eName: "Out-road Parking Management System",
          top: true,
          link: "PARKING_SYSTEM",
          ifLink: !!au["PARKING_SYSTEM"],
        },
        {
          imgSrc: "./img/elec.png",
          mName: "新能源充电系统",
          eName: "New Energy Charging System",
          top: true,
          ifLink: !!au["CHARGING_BUSINESS"],
          link: "CHARGING_BUSINESS",
        },
        {
          imgSrc: "./img/parkingGuidance.png",
          mName: "停车诱导",
          eName: "Parking Guidance System",
          top: true,
          link: "PARKING_GUIDANCE",
          ifLink: !!au["PARKING_GUIDANCE"],
        },
        {
          imgSrc: "./img/dataA.png",
          mName: "数据分析与预测",
          eName: "Data Analysis And Prediction",
          top: true,
          link: "DATA_ANALYSIS",
          ifLink: !!au["DATA_ANALYSIS"],
        },
        // {
        //   imgSrc: "./img/bigdata.png",
        //   mName: "大数据监控中心",
        //   eName: "Large Data Monitoring Center",
        //   top: false,
        //   ifLink: false,
        //   link: "DATA_ANALYSIS",
        // },
        {
          imgSrc: "./img/beian.png",
          mName: "车场备案",
          eName: "Parking Lot Filing",
          top: false,
          link: "PARKING_FILING",
          ifLink: !!au["PARKING_FILING"],
        },
        {
          imgSrc: "./img/merchant.png",
          mName: "商户管理系统",
          eName: "Business Management System",
          top: false,
          link: "TENANT_MANAGE",
          ifLink: !!au["TENANT_MANAGE"],
        },
        {
          imgSrc: "./img/operate.png",
          mName: "运营中心",
          eName: "Operation Center",
          top: false,
          link: "PRODUCT_OPERATION",
          ifLink: !!au["PRODUCT_OPERATION"],
        },
        {
          imgSrc: "./img/financial.png",
          mName: "财务管理",
          eName: "Financial Management",
          top: false,
          link: "FINANCE_CENTER",
          ifLink: !!au["FINANCE_CENTER"],
        },
        {
          imgSrc: "./img/system.png",
          mName: "系统管理",
          eName: "System Management",
          top: false,
          link: "SYSTEM_MANAGEMENT",
          ifLink: !!au["SYSTEM_MANAGEMENT"],
        },
      ],
    };
  },
  components: {
    PortalItem,
  },
  mounted() {},
  created() {
    const arr = [1, 2, 5, 6].includes(this.data.length);
    const arr1 = [7, 8].includes(this.data.length);
    console.log(arr);

    if (arr) {
      this.elementWidth = 700;
    } else if (arr1) {
      this.elementWidth = 900;
    } else {
      this.elementWidth = 1172;
    }
  },
};
</script>
<style scoped lang="stylus">

.pWord
  color:#777
  font-size 16px
  line-height 22px
.portalMain
  max-width 1190px
  max-height 543px
  display flex
  position absolute
  left 0
  top 0
  bottom 0
  right 0
  margin auto
  justify-content center
  flex-wrap wrap
.portalMainTop
  max-width 1190px
  max-height 543px
  display flex
  position absolute
  left 0
  top 0
  bottom 0
  right 0
  margin auto
  justify-content center
  flex-wrap wrap
  padding-top 167px
</style>
