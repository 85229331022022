<template>
  <div
    :class="dataLength > 4 ? 'portalItemMin' : 'portalItemMax'"
    :style="{ background: `url(${require(`${imgSrc}`)}) center / cover` }"
  >
    <div class="itemShow" @click="ifLink ? rootParent.portalClickHandle(item.link) : noAu()">
      <!-- <div class="sTopImg" :style="{ background: `url(${require(`${imgSrc}`)})` }"></div> -->
      <!-- <div style="padding-top: 26px">
        <span class="inline_block heng"></span>
      </div> -->
      <div class="moduleNames">
        <slot name="bottom"></slot>
      </div>
    </div>
    <!-- <div class="itemHove" @click="rootParent.portalClickHandle(item.link)" v-if="ifLink">
      <div class="sTopImg" :style="{ background: `url(${require(`${imgSrc}`)})` }"></div>
      <div style="padding-top: 6px; font-size: 18px !important">
        <div class="moduleName" slot="moduleName">{{ item.mName }}</div>
        <div class="link">
          <i class="el-icon-right" style="font-size: 18px; color: #000"></i>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  inject: ["rootParent"],
  props: {
    // 是否可点击
    canClick: {
      default: true,
    },
    item: {
      default() {
        return {};
      },
    },
    // 是否有权限
    ifLink: {
      default: true,
    },
    top: {
      default: false,
    },
    imgSrc: {
      require: true,
    },
    dataLength: {
      require: true,
    },
  },
  name: "portalItem",
  methods: {
    noAu() {
      this.$message.closeAll();
      this.$message.error("您没有权限！");
    },
  },
  mounted() {},
};
</script>
<style scoped lang="stylus">
  .link
    padding-top 27px
.heng
  width 40px
  height 5px
  background #3B444C
.itemShow, .itemHove
  text-align center
  width 100%
  height 100%
  cursor pointer
.portalItemMin:hover{
  transition:  .3s ease-in-out
  box-shadow: 8px 8px 15px rgb(171, 197, 235, 1)
}
.portalItemMax:hover{
  transition:  .3s ease-in-out
  box-shadow: 8px 8px 15px rgb(171, 197, 235, 1)
}
.portalItemMax
  position relative
  width 280px
  height 386px
  background #fff
  stretch 0
  overflow hidden
  margin 0 5px
.portalItemMin
  position relative
  width 188px
  height 260px
  background #fff
  stretch 0
  overflow hidden
  margin 0 5px

.moduleNameMin
  height 23px
  font-size 17px
  font-weight 400
  color #29364F
  line-height 19px
  font-family Alibaba PuHuiTi;
  text-align left
  padding 23px 0 0 15px
.englishNameMin
  height 27px;
  font-size 11px;
  font-family Arial, Arial;
  font-weight 400;
  color rgba(20,35,62,0.74);
  line-height 14px;
  text-align left
  padding 8px 0 0 15px
.sTopImgMin
  width 175px
  height 165px
  display inline-block
  float right
  position absolute
  bottom 0
  right 0
.moduleNameMax
  text-align left
  padding 34px 0 0 23px
  height: 34px;
  font-size: 25px;
  font-family: Alibaba PuHuiTi;
  font-weight: 400;
  color: #29364F;
  line-height: 29px;
.englishNameMax
  height: 20px;
  font-size: 16px;
  font-family: Alibaba PuHuiTi;
  font-weight: 400;
  color: rgba(20,35,62,0.74);
  line-height: 20px;
  text-align left
  padding 8px 0 0 15px
.sTopImgMax
  width 263px
  height 269px
  display inline-block
  float right
  position absolute
  bottom 0
  right 0
</style>
